/**{
 * @param {string} data
 * @param {string} type - default: 'application/pdf;base64'
 * @return {string}
 */
export function base64toURL(data, type = 'application/pdf;base64') {
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let blob = new Blob([byteArray], { type });
    return URL.createObjectURL(blob);
}

/**
 * @param {string} data
 * @param {string} name of download file
 */
export function base64Download(data, name) {
    var fileURL = base64toURL(data, 'octet/stream');

    downloadFileUrl(fileURL, name);
}

/**
 * @param {string} fileURL 
 * @param {string} name 
 */
export function downloadFileUrl(fileURL, name) {
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.target = '_blank';
    fileLink.setAttribute('download', name);
    document.body.appendChild(fileLink);

    fileLink.click();
}

/**
 * @param {*} data
 */
export function dataToBlob(data) {
    var byteCharacters = data;
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf;base64' });
}

/**
 * @param {*} data
 * @param {Function} callback
 */
export function dataToDataURL(data, callback) {
    var file = this.dataToBlob(data);
    var fileReader = new FileReader();
    fileReader.onload = (e) => callback(e.currentTarget.result);
    fileReader.readAsDataURL(file);
}

/**
 * @param {*} data
 * @param {Function} callback
 */
export function fileToDataURL(file, callback) {
    var fileReader = new FileReader();
    fileReader.onload = (e) => callback(e.currentTarget.result);
    fileReader.readAsDataURL(file);
}
