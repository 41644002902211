import { send } from '../builder/multiquery.client';
import { Query } from '../builder/query';
import { getInstance } from '../../../auth/authWrapper';
import { getClientAdvisorQuery } from '../queries/client.queries';
import { getClientAuthorization } from '../../api/client/client.authorizations';
import { getLocalAuthorization } from '../../api/local/local.authorizations';
import { getUserAuthorization } from '../../api/user/user.authorizations';
import store from '../../../store';
import { SET_MAIN_CLIENT } from '../../../store/mutations/client.types';
import { SET_ADVISOR } from '../../../store/mutations/user.types';

export default function getAdvisorRequest() {
    const clientId = getInstance().getUserClientId();
    const dossierId = getInstance().getUserDossierId();
    return send({
        queries: [
            new Query(getClientAdvisorQuery(clientId, dossierId), 'client')
        ],
        authorizations: [
            getClientAuthorization(),
            getLocalAuthorization(),
            getUserAuthorization()
        ],
        headers: {
            // TODO: remove after merge of #3262
            'X-AUTH-ROLES': 'CLIENT_GET_ONE,LOCAL_GET_ONE,USER_GET_ONE'
        },
        responseTransformer(response) {
            if (response.data.data.client) {
                store._modules.root._children.client.context.commit(SET_MAIN_CLIENT, response.data.data.client);
                
                if (response.data.data.client.rc) {
                    store._modules.root._children.user.context.commit(SET_ADVISOR, response.data.data.client.rc);
                }
            }
        }
    });
}
