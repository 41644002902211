class AuthorizationMapper {
    /**
     *
     * @param {string} audience
     * @returns {string}
     */
    mapAudienceToHeaderName(audience) {
        switch (audience) {
        case process.env.VUE_APP_AUTH0_MS_CGU_AUDIENCE:
            return AuthorizationMapper.HEADER_NAME_API_CGU;

        case process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE:
            return AuthorizationMapper.HEADER_NAME_MS_CLIENT;

        case process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE:
            return AuthorizationMapper.HEADER_NAME_MS_DOCUMENT;

        case process.env.VUE_APP_AUTH0_MS_DOSSIER_AUDIENCE:
            return AuthorizationMapper.HEADER_NAME_MS_DOSSIER;

        case process.env.VUE_APP_AUTH0_MS_LOCAL_AUDIENCE:
            return AuthorizationMapper.HEADER_NAME_MS_LOCAL;

        case process.env.VUE_APP_AUTH0_MS_USER_AUDIENCE:
            return AuthorizationMapper.HEADER_NAME_MS_USER;

        case process.env.VUE_APP_AUTH0_ERP_SERVICE_AUDIENCE:
            return AuthorizationMapper.HEADER_NAME_SERVICE_ERP;

        default:
            throw new Error(`Invalid audience: ${audience}`);
        }
    }
}

AuthorizationMapper.HEADER_NAME_API_CGU = 'x-authorization-api-cgu';
AuthorizationMapper.HEADER_NAME_MS_CLIENT = 'x-authorization-ms-client';
AuthorizationMapper.HEADER_NAME_MS_DOCUMENT = 'x-authorization-ms-document';
AuthorizationMapper.HEADER_NAME_MS_DOSSIER = 'x-authorization-ms-dossier';
AuthorizationMapper.HEADER_NAME_MS_LOCAL = 'x-authorization-ms-local';
AuthorizationMapper.HEADER_NAME_MS_USER = 'x-authorization-ms-user';
AuthorizationMapper.HEADER_NAME_SERVICE_ERP = 'x-authorization-service-erp';
AuthorizationMapper.CUSTOM_HEADERS = [
    AuthorizationMapper.HEADER_NAME_API_CGU,
    AuthorizationMapper.HEADER_NAME_MS_CLIENT,
    AuthorizationMapper.HEADER_NAME_MS_DOCUMENT,
    AuthorizationMapper.HEADER_NAME_MS_DOSSIER,
    AuthorizationMapper.HEADER_NAME_MS_LOCAL,
    AuthorizationMapper.HEADER_NAME_MS_USER,
    AuthorizationMapper.HEADER_NAME_SERVICE_ERP
];

export default AuthorizationMapper;
