export class Query {
    /**
     * 
     * @param {string} queryString - Query raw string
     * @param {string} alias - Query alias
     */
    constructor(
        queryString,
        alias
    ) {
        this.queryString = queryString;
        this.alias = alias;
    }
}
