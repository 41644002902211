<template>
  <div v-if="isVisible" class="loading">
    <div class="d-flex vh-100 justify-content-center align-items-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import emitter from '../../events/emitter.js';
import events from '../../constants/events';

export default {
  data() {
    return {
      isVisible: false
    };
  },
  created() {
    emitter.on(events.loading.show, () => {
      this.isVisible = true;
    });
    emitter.on(events.loading.hide, () => {
      this.isVisible = false;
    });
  }
}
</script>

<style lang="scss" scoped>
.loading {
  background: rgba($color: #fff, $alpha: .5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>
