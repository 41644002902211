import { mapActions } from "vuex";

export default {
    methods: {
        ...mapActions('document', ['getDocumentListByMainDossier']),
        /**
         * 
         * @returns {Promise<Document[]>} array of documents
         */
        getDocumentsWaiting() {
            return this.getDocumentListByMainDossier().then(this.filterDocumentsWaiting);
        },
        /**
         * 
         * @param {*} documents 
         * @returns {Array}
         */
        filterDocumentsWaiting(documents) {
            if (documents) {
                return documents.filter((document) => {
                    return !document.file && (document.type != 'other');
                });
            }
            return [];
        }
    }
}
