import { mergeAuthorizations, send } from '../builder/multiquery.client';
import { Query } from '../builder/query'; 
import { getInstance } from '../../../auth/authWrapper';
import store from '../../../store';
import document_signing_status from '../../../constants/document_signing_status';
import {
    getDocumentListByDossierAuthorization,
    getDocumentSigningByDossierAuthorization,
    getLegalDocumentListByDossierAuthorization
} from '../../api/document/document.authorizations';
import { getDocumentListQuery, getDocumentSigningByDossierQuery, getLegalDocumentListByDossierQuery } from '../queries/document.queries';
import { SET_DOCUMENTS, SET_DOCUMENTS_SIGNING, SET_LEGAL_DOCUMENTS } from '../../../store/mutations/document.types';

export default function getNotificationsRequest() {
    const dossierId = getInstance().getUserDossierId();
    return send({
        queries: [
            new Query(getDocumentListQuery(dossierId), 'documents'),
            new Query(
                getDocumentSigningByDossierQuery(
                    dossierId,
                    [
                        document_signing_status.PROGRESS,
                        document_signing_status.SIGNED,
                        document_signing_status.REFUSED,
                        document_signing_status.EXPIRED
                    ].join('|'),
                ),
                'signingDocuments'
            ),
            new Query(getLegalDocumentListByDossierQuery(dossierId), 'legalDocuments')
        ],
        authorizations: mergeAuthorizations([
            getDocumentListByDossierAuthorization(),
            getDocumentSigningByDossierAuthorization(),
            getLegalDocumentListByDossierAuthorization()
        ]),
        headers: {},
        responseTransformer(response) {
            store._modules.root._children.document.context.commit(SET_DOCUMENTS, response.data.data.documents);
            // merge
            const signingDocuments = [
                ...response.data.data.signingDocuments,
                /*...response.data.data.signedDocuments,
                ...response.data.data.signExpiredDocuments,
                ...response.data.data.signRefusedDocuments*/
            ].sort((a, b) => {
                return a.id - b.id;
            });
            response.data.data.signingDocuments = signingDocuments;
            store._modules.root._children.document.context.commit(SET_DOCUMENTS_SIGNING, response.data.data.signingDocuments);
            store._modules.root._children.document.context.commit(SET_LEGAL_DOCUMENTS, response.data.data.legalDocuments);
        }
    });
}
