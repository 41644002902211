export default {
    methods: {
        /**
         * 
         * @param array links 
         * @param string type 
         * @returns 
         */
        findLinkByType(links, type) {
            if (links) {
                for (let i = 0; i < links.length; i++) {
                    if (links[i].type === type) {
                        return links[i];
                    }
                }
            }
        }
    }
}
