import Contact from "./contact";
import Deactivation from "./deactivation";

class Client {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.id = parseInt(obj.id);
        this.title = obj.title;
        this.firstname = obj.firstname; // string
        this.maidenSurname = obj.maidenSurname; // string
        this.lastname = obj.lastname; // string
        if (obj.contact) {
            this.contact = new Contact(obj.contact);
        }
        this.links = obj.links ?? []; // Link[]
        if (obj.deactivation) {
            this.deactivation = new Deactivation(obj.deactivation);
        }
    }

    /**
     * 
     * @returns {String}
     */
    getFullname() {
        return this.lastname + " " + this.firstname;
    }
}

export default Client;
