<template>
    <!DOCTYPE html>
    <html>
        <!-- begin page header -->
        <head>
            <Header />
            <slot name="head"></slot>
        </head>
        <!-- end of page header -->

        <body>
            <div class="wrapper">
                <!-- nav div begin -->
                <Navbar ref="navbar" />
                <!-- nav div end -->

                <div class="">
                    <div class="row" v-bind:class="{ blur : toBlur }">
                        <!-- side menu begin -->

                        <!-- side menu end -->

                        <div class="col col-lg-12">
                            <!-- user greetings div -->
                            <ContentHeader class="d-none d-lg-flex" />
                            <SideMenu class="d-none d-lg-block"/>
                            <!-- user page title div -->
                            <div class="px-2">
                                <div class="row py-3">
                                    <div class="col-12 col-xl-10 offset-xl-1">
                                        <h3 class="tableau-de-bord text-start mt-3"><strong>{{content_title}}</strong></h3>
                                    </div>
                                </div>

                                <slot name="page-sub-title"></slot>

                                <!-- user begin component contents div -->
                                <div class="row overflow-auto gx-5">
                                    <slot></slot>
                                    <Footer />
                                </div>
                                <!-- end content components div -->
                            </div>
                        </div>
                    </div>
                </div>
                <slot name="modals"></slot>
            </div>

            <Loading />
            <AdvisorFloat v-if="!isMyAdvisor"/>
        </body>
    </html>
</template>

<script>
import Header from "../components/Header.vue";
import Navbar from "../components/Navbar.vue";
import SideMenu from "../components/SideMenu.vue";
import AdvisorFloat from "../components/AdvisorFloat.vue";
import Footer from "../components/Footer.vue"
import ContentHeader from "../components/Common/ContentHeader.vue"
import Loading from "../components/Common/Loading.vue";

export default {
  components: {
    Header,
    Navbar,
    SideMenu,
    Footer,
    ContentHeader,
    Loading,
    AdvisorFloat,
},
  props: {
    content_title: String,
    toBlur: Boolean
  },
  computed: {
    isMyAdvisor() {
      return this.$route.path === '/mon-conseiller';
    },
  },
};
</script>
