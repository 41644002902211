<template>
  <div class="sidebar-gradient-bg">
    <ul
        class="nav justify-content-center"
        id="menu"
    >
      <li class="ms-2 li-sidebar my-2" @mouseover="mouseOverAccueil" @mouseout="mouseOutAccueil">
        <router-link :to="HOME_PATH" class="nav-link px-sm-0 px-2 sidebar-text">
          <img id="btn-home" :src="accueilImgSrc" class="ms-2"/><span
            class="px-2"
        >{{ Home_Link_Label }}</span
        >
        </router-link>
      </li>
      <li class="ms-2 li-sidebar my-2" @mouseover="mouseOverConstituerDossier" @mouseout="mouseOutConstituerDossier">
        <router-link :to="CONSTITUTE_FOLDER_PATH" :class="{ disabled: !isConstituteFolderActive }"
                     class="nav-link px-sm-0 px-2 sidebar-text">
          <img id="btn-constitute" :src="constituerDossierImgSrc" class="ms-2"/><span
            class="px-2"
        >{{ Constitute_Folder_Link_Label }}</span
        >
        <span v-if="badgeCounterWaiting > 0"
            :class='[
              "sidebar-status-dot",
              dataLoading ? "dot-loading" : ""
            ]'>{{ badgeCounterWaiting }}</span>
        </router-link>

      </li>
      <li class="ms-2 li-sidebar my-2" @mouseover="mouseOverSuivreDossier" @mouseout="mouseOutSuivreDossier">
        <router-link :to="TRACK_FOLDER_PATH" class="nav-link px-sm-0 px-2 sidebar-text">
          <img id="btn-track" :src="suivreDossierImgSrc" class="ms-2"/>
          <span
              class="px-2"
          >{{ Track_Folder_Link_Label }}</span>
          <span v-if="badgeCounterSigning > 0"
              :class='[
                dataLoading ? "dot-loading" : "",
                hasSigningDocuments ? "sidebar-status-dot-red" : "sidebar-status-dot"
              ]'>{{ badgeCounterSigning }}</span>
        </router-link>
      </li>
      <li class="ms-2 li-sidebar my-2" @mouseover="mouseOverConseiller" @mouseout="mouseOutConseiller">
        <router-link :to="MY_ADVISOR_PATH" class="nav-link px-sm-0 px-2 sidebar-text">
          <img id="btn-advisor" :src="conseillerImgSrc" class="ms-2"/><span
            class="px-2"
        >{{ My_Advisor_Link_Label }}</span
        >
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import emitter from '../events/emitter';
import events from '../constants/events';
import getNotifications from '../mixins/getNotifications';
import {
  HOME_PATH,
  CONSTITUTE_FOLDER_PATH,
  TRACK_FOLDER_PATH,
  MY_ADVISOR_PATH
} from "../router/pathes";

export default {
  created() {
    this.HOME_PATH = HOME_PATH;
    this.CONSTITUTE_FOLDER_PATH = CONSTITUTE_FOLDER_PATH;
    this.TRACK_FOLDER_PATH = TRACK_FOLDER_PATH;
    this.MY_ADVISOR_PATH = MY_ADVISOR_PATH;
  },
  setup() {
    const store = useStore();

    const getDocuments = computed(() => store.getters[`document/getDocuments`]);
    const getDocumentsSigning = computed(() => store.getters[`document/getDocumentsSigning`]);

    return {
      getDocuments,
      getDocumentsSigning
    }
  },
  data() {
    return {
      Home_Link_Label: "Mon tableau de bord",
      Constitute_Folder_Link_Label: "Mes documents",
      Track_Folder_Link_Label: "Mon dossier",
      My_Advisor_Link_Label: "Mon conseiller",
      isConstituteFolderActive: true,
      isInSuivreDossier: false,
      accueilImgSrc: require('@/assets/images/home_bl.svg'),
      constituerDossierImgSrc: require('@/assets/images/picto-dossier-bl.svg'),
      suivreDossierImgSrc: require('@/assets/images/suivre-dossier-bl.svg'),
      conseillerImgSrc: require('@/assets/images/conseiller-bl.svg'),
      badgeCounterWaiting: 0,
      badgeCounterSigning: 0,
      dataLoading: false,
      hasSigningDocuments: false
    };
  },
  methods: {
    mouseOverAccueil() {
      this.accueilImgSrc = require('@/assets/images/home.svg')
    },
    mouseOutAccueil() {
      this.accueilImgSrc = require('@/assets/images/home_bl.svg')
    },
    mouseOverConstituerDossier() {
      this.constituerDossierImgSrc = require('@/assets/images/picto-dossier.svg')
    },
    mouseOutConstituerDossier() {
      this.constituerDossierImgSrc = require('@/assets/images/picto-dossier-bl.svg')
    },
    mouseOverSuivreDossier() {
      this.suivreDossierImgSrc = require('@/assets/images/suivre-dossier.svg')
    },
    mouseOutSuivreDossier() {
      this.suivreDossierImgSrc = require('@/assets/images/suivre-dossier-bl.svg')
    },
    mouseOverConseiller() {
      this.conseillerImgSrc = require('@/assets/images/conseiller.svg')
    },
    mouseOutConseiller() {
      this.conseillerImgSrc = require('@/assets/images/conseiller-bl.svg')
    },
    /**
     * request data
     */
    getData() {
      this.dataLoading = true;
      this.getNotifications()
        .then(this.whenReceiveNotifications)
        .finally(() => {
          this.dataLoading = false;
        });
    },
    /**
     * @param {Object} notifications
     */
    whenReceiveNotifications(notifications) {
      this.badgeCounterWaiting = notifications.nbDocumentsWaiting;
      this.badgeCounterSigning = notifications.nbDocumentsToValidate + notifications.nbDocumentsSigning + notifications.nbDocumentsJuridique;
      this.hasSigningDocuments = notifications.nbDocumentsSigning > 0;
    }
  },
  computed: {},
  mixins: [getNotifications],
  mounted() {
    emitter.on(events.notifications.refresh, () => {
      this.getData();
    });
    //if we are in suivre-dossier notification counter bg is set to red
    this.whenReceiveNotifications({
      nbDocumentsWaiting: this.filterDocumentsWaiting(this.getDocuments).length,
      nbDocumentsSigning: this.getDocumentsSigning.length
    });
    this.getData();
  },
  unmounted() {
    emitter.off(events.notifications.refresh);
  }
}
</script>

<style lang="scss">
@import '../assets/css/colors.scss';

@keyframes dot-loading {
  0% {
    background-color: lighten($color-red, 10%);
  }

  50% {
    background-color: lighten($color-red, 50%);
  }

  100% {
    background-color: $color-red;
  }
}
.dot-loading {
  animation: dot-loading 2s linear infinite;
}

.sidebar-status-dot {
  height: 25px;
  line-height: 25px;
  width: 25px;
  background-color: $color-warn;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-right: 8px;
  color: white;
}

.sidebar-status-dot-red {
  @extend .sidebar-status-dot;
  background-color: $color-red;
}


  .li-sidebar:hover,
  .router-link-active {
    background-color: $color-primary;
    border-radius: 30px;
  }

  .li-sidebar:hover span,
  .router-link-active span{
    color: white;
  }

  .router-link-active #btn-home {
    content: url('../assets/images/home.svg');
  }

  .router-link-active #btn-constitute {
    content: url('../assets/images/picto-dossier.svg');
  }

  .router-link-active #btn-track {
    content: url('../assets/images/suivre-dossier.svg');
  }

  .router-link-active #btn-advisor {
    content: url('../assets/images/conseiller.svg');
  }


@media (min-width: 1912px) {
  .navbar-pos {
    position: relative;
  }

  .navbar-brand {
    margin-left: 2%;
  }
}

.sidebar-gradient-bg {
  background: white;
  box-shadow: 0px 20px 26px #0000001D;
}

#menu li a span {
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1.52px;
}

#menu li a img {
  width: 15px;
}

.sidebar-text {
  color: $color-primary;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 991.5px) {
  .nav {
    justify-content: space-evenly;
    //display: block;
  }

  .ps-4 {
    padding-left: 0 !important;
  }
}
</style>
