const events = {
    document: {
        reader: {
            hide: 'hide-document-reader',
            show: 'show-document-reader',
        },
        legal: {
            hide: 'hide-document-legal',
            show: 'show-document-legal',
        },
        signing: {
            hide: 'hide-document-signing',
            show: 'show-document-signing',
        }
    },
    home: {
        cguDone: 'home-cgu-done',
        constituteFolderDone: 'home-constitute-folder-done',
        notificationsDone: 'home-notification-done',
        progressDone: 'home-progress-done'
    },
    loading: {
        hide: 'hide-loading',
        show: 'show-loading'
    },
    notifications: {
        refresh: 'notifications-badges-refresh'
    }
};

export default events;
