import AuthApiService from '../../api/auth.api';
import axios from '../../api';
import AuthorizationMapper from '../mapper/authorizationMapper';
import { MultiQueryBuilder } from './multiquery.builder';
import { Authorization } from './authorization';

/**
 * 
 * @param {Array} authorizations 
 * @returns 
 */
function getTokens(authorizations) {
    const promises = [];
    // add promises
    for (let i in authorizations) {
        promises.push(
            AuthApiService(
                authorizations[i].audience,
                authorizations[i].scope
            ).getToken()
        );
    }

    return Promise.all(promises).then((tokens) => {
        return tokens;
    }).catch((err) => {
        console.error(err);
        alert('Merci de contacter votre commercial');
        return;
    });
}

/**
 * regroup authorizations by scope
 * @param {Array} authorizations 
 * @returns 
 */
export function mergeAuthorizations(authorizations) {
    let audiences = {};
    for (let i in authorizations) {
        if (Object.prototype.hasOwnProperty.call(audiences, authorizations[i].audience) === false) {
            audiences[authorizations[i].audience] = [];
        }
        audiences[authorizations[i].audience].push(authorizations[i].scope);
    }

    let authorizationsByAudience = [];
    for (let audience in audiences) {
        const scope = audiences[audience];
        authorizationsByAudience.push(
            new Authorization(
                audience,
                scope
            )
        );
    }
    return authorizationsByAudience;
}

/**
 * 
 * @param {*} param0 
 * @param {Object} param0.headers
 * @param {Object} param0.promises
 * @param {Object} param0.tokens
 */
function mergeHeaders({ headers, authorizations, tokens }) {
    const headersBis = {
        'Content-Type': 'application/json',
        ...headers
    };
    const mapper = new AuthorizationMapper();
    for (let i in authorizations) {
        headersBis[mapper.mapAudienceToHeaderName(authorizations[i].audience)] = tokens[i];
    }
    return headersBis;
}

/**
 * 
 * @param {Object} param0
 * @param {string} param0.query
 * @param {Object} param0.headers 
 * @returns 
 */
async function sendRequest({
    query,
    headers
}) {
    const data = JSON.stringify({
        query,
        variables: {}
    });
    const config = {
        method: 'post',
        headers,
        data: data
    };
    return axios(config);
}

/**
 * 
 * @param {Object} param0 
 * @param {Array} param0.queries array of Query
 * @param {Array} param0.authorizations array of Authorization
 * @param {Object} param0.headers 
 * @param {Function} param0.responseTransformer 
 */
export function send({
    queries,
    authorizations,
    headers,
    responseTransformer
}) {
    return new Promise((resolve, reject) => {
        getTokens(authorizations).then((tokens) => {
            const headersBis = mergeHeaders({ headers, authorizations, tokens });
            const builder = new MultiQueryBuilder(
                queries
            );
            const queryString = builder.build();
 
            return sendRequest({ query: queryString, headers: headersBis });
        }).then((response) => {
            if (typeof responseTransformer === 'function') {
                responseTransformer(response);
            }
            resolve(response.data.data);
        }).catch((err) => {
            console.error(err);
            alert('Merci de contacter votre commercial');
            reject(err);
        });
    });
}
