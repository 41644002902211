<template>
    <div class="bg-family contentheader d-flex justify-content-center align-items-center">
      <h3 class=""><strong>Bienvenue</strong> sur votre <strong>espace client Starto</strong></h3>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const fullName = computed(() => store.getters[`getUserFullName`]);

    return {
      fullName
    }
  },
}
</script>

<style>
  .contentheader h3 {
    font-size: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
    border: 2px solid #FFFFFF;
    padding: 2rem 5rem;
    width: fit-content;
    color: white;
  }
</style>
