import { mapActions } from "vuex";
import local_type from '../constants/local_type';
import user_group_name from '../constants/user_group_name';
import getLocalByUser from '../mixins/getLocalByUser';

export default {
    methods: {
        ...mapActions('user', ['getUserSuperiors']),
        /**
         * @description Récupère les informations de contact du CAN (numéros de téléphone, disponibilité, etc.)
         * @returns {Object}
         */
        getCanContact() {
            return {
                Block_Title: "MON CONSEILLER",
                Contact_Name: 'L\'équipe Starto',
                Availability_Slot : "Joignable du lundi au vendredi de 8h à 19h",
                Phones_Numbers: [process.env.VUE_APP_NUMERO_VERT_PHONE_NUMBER],
                Gender: 'male'
            }
        },
        /**
         * @param user RC
         * @return array
         */
        getPhonesByUser(user) {
            const phones = [];

            // add rc phones number
            if (user.contact) {
                if (user.contact.phones) {
                    for (let i = 0; i < user.contact.phones.length; i++) {
                        phones.push(user.contact.phones[i]);
                    }
                }
            } else {
                console.warn('`user` object doesn’t have property `contact`');
            }
            return phones;
        },
        /**
         * @param user RC
         * @return {Promise<user[]>}
         */
        async getSuperiorsContactByUser(user) {
            const users = [];

            // check local type to another phone number
            const local = user.local ? user.local : await this.getLocalByUser(user);
            if (local) {
                if (local.type == local_type.PLATEFORME) {
                    // il n'y a pas de n° distance
                } else if (local.type == local_type.AGENCE) {
                    const superiors = await this.getUserSuperiors({
                        userId: user.id,
                        groupName: user_group_name.ATC
                    });
                    if (superiors.length > 0) {
                        for (let i = 0; i < superiors.length; i++) {
                            if (superiors[i].contact && superiors[i].contact.phones) {
                                users.push(superiors[i]);
                            }
                        }
                    }
                }
            }
            return users;
        }
    },
    mixins: [getLocalByUser]
}
