import { mapActions } from "vuex";
import client_link_type from '../constants/client_link_type.js';
import findLinkByType from '../mixins/findLinkByType';

export default {
    methods: {
        ...mapActions('local', ['getLocal']),
        /**
         * 
         * @returns {Promise<Object>} Local
         */
        getLocalByUser(user) {
            const local_link = this.findLinkByType(user.links, client_link_type.LOCAL);

            if (local_link) {
                const local_id = parseInt(local_link.id, 10);

                if (local_id) {
                    return this.getLocal(local_id);
                }
            } else {
                console.warn('`user` object doesn’t have a `local` link');
            }
        }
    },
    mixins: [findLinkByType]
}
