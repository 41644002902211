import { mapActions } from "vuex"
import document_signing_status from '../constants/document_signing_status'
import getDocumentsWaiting from '../mixins/getDocumentsWaiting';
import getNotificationsRequest from '../services/graphql/requests/getNotificationsRequest';

export default {
    methods: {
        ...mapActions('document', ['getDocumentSigningByMainDossier', 'getLegalDocumentListByMainDossier']),
        /**
         * 
         * @returns {Promise<Object>} {
         *  documentsSigning: Array,
         *  nbDocumentsWaiting: Int
         *  nbDocumentsToValidate: Int
         *  nbDocumentsSigning: Int
         * }
         */
        async getNotifications() {
            let notifications = {
                documentsSigning: [],
                nbDocumentsWaiting: 0,
                nbDocumentsToValidate: 0,
                nbDocumentsSigning: 0,
                nbDocumentsJuridique: 0
            };

            try {
                let data = await getNotificationsRequest();
                // waiting documents
                
                notifications.nbDocumentsWaiting = this.filterDocumentsWaiting(data.documents).length;
                // documents to validate
                if (data.legalDocuments) {
                    notifications.nbDocumentsJuridique = 0;
                    data.legalDocuments.forEach(element => {
                        if (element.variable == 'TEMPLATE_JURIDIQUE_RAC_ASSURANCE_MANDAT' && element.dateValidation == null)  notifications.nbDocumentsJuridique++;
                    });
                    notifications.nbDocumentsToValidate = this.reduceNbDocumentsToValidate(data.legalDocuments) - notifications.nbDocumentsJuridique;
                }
                // signing documents
                if (data.signingDocuments) {
                    for(let i = 0; i < data.signingDocuments.length; i++) {
                        const document_signing = data.signingDocuments[i];
                        notifications.documentsSigning.push(document_signing);
                        if (document_signing.status === document_signing_status.PROGRESS) {
                            notifications.nbDocumentsSigning++;
                        }
                    }
                }
            } catch (exception) {
                console.error('getNotifications exception:', exception);
            }
            return notifications;
        },
        /**
         * 
         * @param {*} documents legal documents
         * @returns {int} number of documents to validate
         */
        reduceNbDocumentsToValidate(documents) {
            if (documents) {
                return documents.reduce((acc, doc) => {
                    return acc + ((doc.dateValidation == null && doc.dateRefus == null) ? 1 : 0);
                }, 0);
            }
            return 0;
        }
    },
    mixins: [getDocumentsWaiting]
}
