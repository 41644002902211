class Contact {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.phones = obj.phones ?? []; // string[]
        this.fax = obj.fax; // string
        this.email = obj.email; // string
        this.acceptEmailing = obj.acceptEmailing; // boolean
        this.acceptSms = obj.acceptSms; // boolean
        this.acceptPartners = obj.acceptPartners;
    }
}

export default Contact;
