import { Query } from "./query";

export class MultiQueryBuilder {
    /**
     * @var []
     */
    #queries = [];

    /**
     * 
     * @param {array} queries(array of Query) 
     */
    constructor(queries) {
        this.#queries = queries
    }

    /**
     * @return {string}
     */
    build() {
        return `query {
            ${this.#buildQueries()}
        }`;
    }

    /**
     * @return {string}
     */
    #buildQueries() {
        let string = '';
        this.#queries.forEach((query) => {
            if (query instanceof Query) {
                string += this.buildQuery(query);
            }
        });
        return string;
    }

    /**
     * 
     * @param {Query} query
     *
     * @return {string}
     */
    buildQuery(query) {
        return `${query.alias + ': '}${query.queryString}\n`;
    }
}
