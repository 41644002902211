class Deactivation {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.date = parseInt(obj.date);
    }
}

export default Deactivation;
